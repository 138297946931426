<template>
  <div class="main">
    <div class="backPage" @click="$router.go(-1)">返回</div>
    <heand :tagnum="tagnum"></heand>
    <div class="chuangx_ziytop">
      <dl>
        <dt>{{ currentData.name }}</dt>
      </dl>
    </div>

    <div class="chuangx_fffbox">
      <h1 class="chuang_tit">
        一、项目名称：<span style="font-weight: 500; font-size: 14px;">{{
          currentData.name
        }}</span>
      </h1>
      <h1 class="chuang_tit">
        二、项目所属区域：<span style="font-weight: 500; font-size: 14px;">{{
          currentData.district
        }}</span>
      </h1>
      <h1 class="chuang_tit">三、项目概况：</h1>
      <div class="chuangx_jsh">
        <p>（一）项目类型：{{ currentData.type }}</p>
        <p>（二）总 投 资：{{ currentData.money }}</p>
        <p>（三）项目背景：{{ currentData.background }}</p>
        <p>（四）项目内容：{{ currentData.content }}</p>
      </div>
      <h1 class="chuang_tit">四、拟引资方式及内容：</h1>
      <div
        class="chuangx_jsh"
        style="text-indent: 24px;"
        v-html="currentData.way"
      ></div>
      <h1 class="chuang_tit">五、招商项目联系方式：</h1>
      <div class="chuangx_jsh">
        <p>招商单位：{{ currentData.company }}</p>
        <p>联系方式：{{ currentData.contact }}</p>
        <p v-if="currentData.email">邮箱：{{ currentData.email }}</p>
      </div>
    </div>

    <!--     <div class="chuangx_fffbox">-->
    <!--        <h1 class="chuang_tit">地图</h1>-->
    <!--        <div class="chuangx_map">地图地图地图地图地图</div>-->
    <!--    </div>-->
  </div>
</template>

<script>
import { keyProject } from "@/api/index";

export default {
  name: "chdetsils1",
  data() {
    return {
      tagnum: "",
      id: "",
      currentData: "",
    };
  },
  created() {
    this.id = this.$route.query.id;
    console.log(this.id);
    this.getData();
  },
  methods: {
    async getData() {
      try {
        const res = await keyProject({
          id: this.id,
        });
        this.currentData = res;
      } catch (err) {}
    },
  },
};
</script>

<style lang="less" scoped>
.main {
  background: #f2f4f7;
  overflow: hidden;
  zoom: 1;
}
.chuangx_ziytop {
  margin: 20px auto;
  background: url(../../assets/industrial/chuangx_top2.png) no-repeat;
  width: 1360px;
  height: 160px;
  padding: 40px;
  color: #fff;

  dl {
    overflow: hidden;
    zoom: 1;
    dt {
      font-size: 36px;
      font-weight: bold;
      float: left;
      line-height: 120px;
    }
  }
}

.chuangx_fffbox {
  width: 1360px;
  overflow: hidden;
  zoom: 1;
  background: #fff;
  text-align: left;
  margin: 0 auto 20px auto;
  padding-bottom: 20px;

  .chuang_tit {
    font-size: 18px;
    color: #333;
    padding: 16px;
  }
  .chuangx_jsh {
    font-size: 15px;
    line-height: 26px;
    padding: 0 16px;
    p {
      text-indent: 24px;
    }
    .shy_add {
      font-weight: bold;
      color: #fe6a00;
      text-indent: 24px;
      margin: 20px 0 20px 0;
    }
  }
  .chuangx_map {
    padding: 0 16px;
    height: 300px;
  }
}
</style>
